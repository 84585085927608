import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
//home prop para determinar que se agrege el gradiente y el texto a la imagen
//solo si esta en la pagina home
const StyledHero = ({ img, className, children, home }) => {
  return (
    <BackgroundImage className={className} fluid={img} home={home}>
      {children}
    </BackgroundImage>
  )
}
//usamos styled components para decidir cuando ejecutar linear gradient y cuando no
export default styled(StyledHero)`
  min-height: ${props => (props.home ? "calc(100vh - 62px)" : "50vh")};
  background: ${props =>
    props.home
      ? "linear-gradient(rgba(241, 157, 89, 0.4), rgba(0, 0, 0, 0.7))"
      : "none"};
  background-position: center;
  background-size: cover;
  opacity: 1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
`
